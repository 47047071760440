
.downloadLink {
    margin-left: 15%;
}
.downloadBtn2 {
    visibility: hidden;
}
@media only screen and (max-width: 768px) {
    .downloadBtn {
        visibility: hidden;
    }
    .downloadBtn2 {
        visibility: visible;
    }

}
