.faq-main-b{
    display: grid;
    padding-top: 10px;
    justify-items: center;
    grid-template-columns: 1fr;
    background-color: rgb(0,51,204);
}
.faq-main-w{
    display: grid;
    padding-top: 10px;
    justify-items: center;
    grid-template-columns: 1fr;
    background-color: white;
}
.faq-title-b{
    color: white;
    margin-top: 60px;
    font-size: 35px;
    justify-self: center;
}
.faq-title-w{
    color: rgb(0,51,204);
    margin-top: 60px;
    font-size: 35px;
    justify-self: center;
}
.faq-main-b .after-50px:after,
.faq-main-w .after-50px:after{
    left: 180px;
}
.faq-item-list{
    width: 100%;
    height: fit-content;
    display: grid;
    margin-bottom: 50px;
    margin-top: 20px;
    justify-items: center;
    grid-template-columns: 1fr;
}
.faq-item-b{
    width:80%;
    max-width: 1000px;
    background-color: white;
    height: fit-content;
    padding: 20px 30px;
    margin-bottom: 20px;
    border-radius: 15px;
    box-shadow: 3px 3px 5px rgba(209,216,43, 0.688);
}
.faq-item-w{
    background-color: rgb(0,51,204);
    width:80%;
    max-width: 1000px;
    height: fit-content;
    padding: 20px 30px;
    margin-bottom: 20px;
    border-radius: 15px;
    box-shadow: 3px 3px 5px rgba(21, 219, 150, 0.688);
}
.faq-question-b{
    font-size: 22px;
    color:rgb(0,51,204);
    display: inline-block;
    margin: 0;
    max-width: 80%;
}
.faq-question-w{
    color: white;
    font-size: 22px;
    display: inline-block;
    margin: 0;
    max-width: 80%;
}
.faq-answer-icon-b{
    float: right;
    color: RGB(209,216,43);
    font-size: 22px;
}
.faq-answer-icon-w{
    float: right;
    color: rgb(21,219,149);
    font-size: 22px;
}
.faq-answer-icon-b:hover{
    color: rgb(0,51,204);
    transition: all .3s ease-out;
}
.faq-answer-icon-w:hover{
    color: white;
    transition: all .3s ease-out;
}
.faq-checbox{
    display: none;
}
.faq-answer-b{
    display: none;
    margin: 0;
    margin-top: 10px;
}
.faq-answer-w{
    color: white;
    display: none;
    margin: 0;
    margin-top: 10px;
}
.faq-item-b input:checked ~ .faq-answer-b,
.faq-item-w input:checked ~ .faq-answer-w{
    display: block;
}
.faq-item-b input:checked ~ .faq-answer-icon-b,
.faq-item-w input:checked ~ .faq-answer-icon-w{
    transform: rotate(180deg);
    transition: all .2s ease-out;
}

@media (max-width: 432px) {
    .faq-title-b,
    .faq-title-w{
        font-size: 28px;
    }
    .faq-main-b .after-50px:after,
    .faq-main-w .after-50px:after{
        left: 150px;
    }
}