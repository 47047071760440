/* .title-and-intro{
    height: 1000px;
    width: 1000px;
    
} */
.t-and-c-main{
    padding-top: 109px;
    background-color: rgb(0,51,204);
}
#tc-intro{
    display: grid;
    padding-top: 30px;
    padding-left: 10%;
    padding-right: 10%;
}
.tc-title{
    color: white;
    font-size: 35px;
    justify-self: center;
}
#tc-intro .after-50px:after{
    left: 140px;
}
#tc-intro .section-title{
    font-size: 25px;
    color:rgb(21,219,149);
}
#tc-intro .section-text{
    padding: 0 5%;
    color: white;
    margin-bottom: 10px;
}
.section-block{
    background-color: white;
    margin: 30px 8% 30px 8%;
    border-radius: 15px;
    padding: 30px 30px;
}
.section-block .section-title{
    color: rgb(0,51,204);
    font-size: 25px;
}
.section-item{
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 10px;
}
.bold{
    font-weight: 500;
}
.section-sub-item,
.section-sub-sub-item{
    padding-left: 5%;
    padding-bottom: 10px;
}