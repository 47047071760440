
.slider{
    width: 800px;
    height: 500px;
    border-radius: 10px;
    overflow: hidden;
}

.slides{
    width: 500%;
    height: 500px;
    display: flex;
}

.slides input{
    display: none;
}

.slide{
    width: 20%;
    transition: 8s;
}

.slide img{
    width: 800px;
    height: 500px;
}

/*css for manual slide navigation*/

.navigation-manual{
    position: absolute;
    width: 800px;
    margin-top: -40px;
    display: flex;
    justify-content: center;
}
.tek-Images{
    border-radius: 20px;
}

.manual-btn{
    border: 2px solid #40D3DC;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    transition: 10s;
}

.manual-btn:not(:last-child){
    margin-right: 40px;
}

.manual-btn:hover{
    background: #40D3DC;
}

#radio1:checked ~ .first{
    margin-left: 0;
}

#radio2:checked ~ .first{
    margin-left: -20%;
}

#radio3:checked ~ .first{
    margin-left: -40%;
}

#radio4:checked ~ .first{
    margin-left: -60%;
}
#radio5:checked ~ .first{
    margin-left: -80%;
}
#radio6:checked ~ .first{
    margin-left: -100%;
}
#radio7:checked ~ .first{
    margin-left: -120%;
}
/*css for automatic navigation*/

.navigation-auto{
    position: absolute;
    display: flex;
    width: 800px;
    justify-content: center;
    margin-top: 460px;
}

.navigation-auto div{
    border: 2px solid #40D3DC;
    padding: 5px;
    border-radius: 10px;
    transition: 1s;
}

.navigation-auto div:not(:last-child){
    margin-right: 40px;
}

#radio1:checked ~ .navigation-auto .auto-btn1{
    background: #40D3DC;
}

#radio2:checked ~ .navigation-auto .auto-btn2{
    background: #40D3DC;
}

#radio3:checked ~ .navigation-auto .auto-btn3{
    background: #40D3DC;
}

#radio4:checked ~ .navigation-auto .auto-btn4{
    background: #40D3DC;
}
#radio5:checked ~ .navigation-auto .auto-btn5{
    background: #40D3DC;
}

#radio6:checked ~ .navigation-auto .auto-btn6{
    background: #40D3DC;
}
#radio7:checked ~ .navigation-auto .auto-btn7{
    background: #40D3DC;
}

@media only screen and (max-width: 600px) {
    .slides {
        width: 230%;
        height: 330px;
        display: flex;
    }

    .navigation-auto{
        position: absolute;
        display: flex;
        width: 300px;
        justify-content: center;
        margin-top: 500px;
    }

    .navigation-manual {
        display: none;
    }

    .slide{
        width: 20%;
        transition: 10s;
    }

    .slide img{
        width: 500px;
        height: 350px;
    }

}

