@media only screen and (max-width: 600px) {

    #login{
        display: block;

    }
    #createAccountBtn{
        display: block;
    }

    #navbarSupportedContent{
        background: #0033CC;
        
    }
    #navColor{
        color:#718096;
    }
    .nav-item{
        color: white !important;
    }
}
