.subTitle {
    font-size: 30px;
    font-weight: bold;
}

.btn-xl {
    padding: 10px 20px;
    font-size: 20px;
    border-radius: 10px;
    width:100%;
}
.verifySet{
    border-radius: 50px;
    border: 0px;
}
.verify_btn{
    background-color: #1C5C99;
    color: white;
}
.verify_btn:hover{
    color: white;  
}
.verifySetss{
    border-radius: 50px;
    width: 80px;
    height: 80px;
    border: 0px; 
}
