
.fdOgaSave{
    /* background-color: aquamarine; */
    justify-content: center;
    text-align: center;
}
.yepImagesIm{
    width: 400px;
}
.downloadBtn{
    justify-content: center;
    text-align: center;
    margin-left: -15%;
}
.setOgaappLabe-Top{
    background-color: #002862;
    color: white;
    font-weight: bold;
}
.gitFullSide{
    width: 300px;
}


@media(max-width:664px){
   
    .gitFullSideDiv{
       /* padding: 10px; */
        justify-content: center;
        text-align: center;
    }
    .tecMoreOGAMoved{
        margin-top: 20% !important;
        }
        
}
