.side-menu-bar{
    position: fixed;
    background-color: white;
    height: 95%;
    width: 200px;
    margin-top: 89px;
    display: grid;
    grid-template-rows: 51px 1fr;
    padding-left: 20px;
    padding-right: 10px;
    
}
.side-menu-btn-cont{
    width: 100%;
    display:grid;
    align-items: center;
    justify-items: right;
    padding-right: 10px;
}
.bi-arrow-left:hover{  
    color: rgb(0,51,204);
}
.bi-arrow-left{
    font-size: 30px;
    height:fit-content;
    color:rgb(21,219,149);
}
.side-menu-list{
    height:fit-content;
    max-height: 100% ;
    overflow: auto;
}
.side-menu-item{
    border-bottom: 1px solid rgb(21,219,149);
    margin:0;
    padding: 20px 0;
    
}
.checkbox{
    display: none;
}

#side-menu-check:checked ~ .side-menu-bar .side-menu-list{
    visibility: collapse;
    transition: all .1s ease-out;
}
#side-menu-check:checked ~ .side-menu-bar{
    height: 50px;
    margin-top: 99px;
    margin-left: 10px;
    width:50px;
    padding: 0;
    border-radius: 25px;
    border: 1px solid rgb(231, 231, 231);
    box-shadow: 2px 2px 5px rgb(70, 69, 69);
    transition: all .2s ease-out;
}
#side-menu-check:checked ~ .side-menu-bar .side-menu-btn-cont i{
    transform: rotate( -90deg);
    transition: all .4s ease-out;
}
#side-menu-check:checked ~ .t-and-c-main{
    grid-template-columns: 1fr;
}
#side-menu-check:checked ~ .t-and-c-main .side-menu-shadow{
    display: none;
}
.side-menu-footer-align{
    height: 640px
}

@media (max-width:990px) {
    .side-menu-bar{
        margin-top: 109px;
    }
    #side-menu-check:checked ~ .side-menu-bar{
        margin-top: 119px;
    }
    .side-menu-footer-align{
        height: 850px
    }
}
