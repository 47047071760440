.createBtn {
    margin-left: 85px;
    margin-bottom: 5px;
}
.ems-webImage {
    margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
    .createBtn {
        margin-left: auto;
    }
    .ems-webImage {
        width: auto;
        height: auto;
    }

}

.textColor {
    color: gold;
}
